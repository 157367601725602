import { useI18n } from "vue-i18n";

function getPreset() {
  const { t } = useI18n();
  return [
    {
      id: 1,
      name: t("message.buying_journal"),
      type: "buying",
      isMagicCode: true,
      icon: `
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path
          d="M6 4h11a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-11a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1m3 0v18"
        />
        <line x1="13" y1="8" x2="15" y2="8" />
        <line x1="13" y1="12" x2="15" y2="12" />`,
    },
    {
      id: 2,
      name: t("message.selling_journal"),
      type: "selling",
      isMagicCode: true,
      icon: `<path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <rect x="5" y="3" width="14" height="18" rx="2" />
      <line x1="9" y1="7" x2="15" y2="7" />
      <line x1="9" y1="11" x2="15" y2="11" />
      <line x1="9" y1="15" x2="13" y2="15" />`,
    },
    {
      id: 3,
      name: t("message.bank_journal"),
      type: "bank",
      isMagicCode: true,
      icon: `<path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <line x1="3" y1="21" x2="21" y2="21" />
      <line x1="3" y1="10" x2="21" y2="10" />
      <polyline points="5 6 12 3 19 6" />
      <line x1="4" y1="10" x2="4" y2="21" />
      <line x1="20" y1="10" x2="20" y2="21" />
      <line x1="8" y1="14" x2="8" y2="17" />
      <line x1="12" y1="14" x2="12" y2="17" />
      <line x1="16" y1="14" x2="16" y2="17" />`,
    },
    {
      id: 4,
      name: t("message.cash_journal"),
      type: "cash",
      isMagicCode: true,
      icon: `<path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M21 6h-4a3 3 0 0 0 0 6h1a3 3 0 0 1 0 6h-4" />
      <path d="M10 18h-1a6 6 0 1 1 0 -12h1" />
      <path d="M17 20v-2" />
      <path d="M18 6v-2" />`,
    },
    {
      id: 5,
      name: t("message.various_journal"),
      type: "misc",
      isMagicCode: true,
      icon: ` <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <line x1="9" y1="6" x2="20" y2="6" />
      <line x1="9" y1="12" x2="20" y2="12" />
      <line x1="9" y1="18" x2="20" y2="18" />
      <line x1="5" y1="6" x2="5" y2="6.01" />
      <line x1="5" y1="12" x2="5" y2="12.01" />
      <line x1="5" y1="18" x2="5" y2="18.01" />`,
    },
    {
      id: 6,
      name: t("message.pay_journal"),
      type: "pay",
      isMagicCode: true,
      icon: ` <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <circle cx="12" cy="12" r="3" />
      <rect x="3" y="6" width="18" height="12" rx="2" />
      <line x1="18" y1="12" x2="18.01" y2="12" />
      <line x1="6" y1="12" x2="6.01" y2="12" />`,
    },
    {
      id: 7,
      name: t("message.carry_journal"),
      type: "carry",
      isMagicCode: false,
      icon: ` <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <circle cx="12" cy="12" r="3" />
      <rect x="3" y="6" width="18" height="12" rx="2" />
      <line x1="18" y1="12" x2="18.01" y2="12" />
      <line x1="6" y1="12" x2="6.01" y2="12" />`,
    },
  ];
}

export default getPreset;
