<template>
  <SubJournalPopup
    @setJournal="(journal) => setJournal(journal)"
    v-if="popupTriggers.popup == 'sub-journal'"
    :journal="popupTriggers.journal"
  ></SubJournalPopup>
  <h2 class="heading text-center">{{ $t("message.select_journal") }}</h2>
  <div class="clearfix"></div>
  <br />
  <br />
  <div class="grid gap grid-5">
    <div
      v-for="journal in journalPresetList"
      :key="journal.id"
      class="card-list"
    >
      <div
        :class="[
          'card-list__body',
          state.selectedJournal == journal.type ? 'active' : '',
        ]"
        @click="selectJournal(journal.type)"
        @keydown.enter="selectJournal(journal.type)"
        tabindex="0"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-notebook"
          width="48"
          height="48"
          viewBox="0 0 24 24"
          stroke-linecap="round"
          stroke-linejoin="round"
          v-html="journal.icon"
        ></svg>
        <div class="clearfix"></div>
        <br />
        <span>{{ journal.name }}</span>
      </div>
      <button
        class="form-btn"
        style="position: relative; left: 170px"
        @click="togglePopup('sub-journal', journal)"
        v-if="journal.type == 'bank' || journal.type == 'cash'"
      >
        +
      </button>
      <!-- <div
        :class="[
          'card-list__body',
          state.selectedJournal == 'buying' ? 'active' : '',
        ]"
        @click="selectJournal('buying')"
        @keydown.enter="selectJournal('buying')"
        tabindex="0"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-notebook"
          width="48"
          height="48"
          viewBox="0 0 24 24"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path
            d="M6 4h11a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-11a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1m3 0v18"
          />
          <line x1="13" y1="8" x2="15" y2="8" />
          <line x1="13" y1="12" x2="15" y2="12" />
        </svg>
        <div class="clearfix"></div>
        <br />
        <span>{{ $t("message.buying_journal") }}</span>
      </div> -->
    </div>
    <!-- <div class="card-list">
      <div
        :class="[
          'card-list__body',
          state.selectedJournal == 'selling' ? 'active' : '',
        ]"
        @click="selectJournal('selling')"
        @keydown.enter="selectJournal('selling')"
        tabindex="0"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-notes"
          width="48"
          height="48"
          viewBox="0 0 24 24"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect x="5" y="3" width="14" height="18" rx="2" />
          <line x1="9" y1="7" x2="15" y2="7" />
          <line x1="9" y1="11" x2="15" y2="11" />
          <line x1="9" y1="15" x2="13" y2="15" />
        </svg>
        <div class="clearfix"></div>
        <br />
        <span>{{ $t("message.selling_journal") }}</span>
      </div>
    </div>
    <div class="card-list">
      <div
        :class="[
          'card-list__body',
          state.selectedJournal == 'bank' ? 'active' : '',
        ]"
        @click="selectJournal('bank')"
        @keydown.enter="selectJournal('bank')"
        tabindex="0"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-building-bank"
          width="48"
          height="48"
          viewBox="0 0 24 24"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <line x1="3" y1="21" x2="21" y2="21" />
          <line x1="3" y1="10" x2="21" y2="10" />
          <polyline points="5 6 12 3 19 6" />
          <line x1="4" y1="10" x2="4" y2="21" />
          <line x1="20" y1="10" x2="20" y2="21" />
          <line x1="8" y1="14" x2="8" y2="17" />
          <line x1="12" y1="14" x2="12" y2="17" />
          <line x1="16" y1="14" x2="16" y2="17" />
        </svg>
        <div class="clearfix"></div>
        <br />
        <span>{{ $t("message.bank_journal") }}</span>
      </div>
    </div>
    <div class="card-list">
      <div
        :class="[
          'card-list__body',
          state.selectedJournal == 'cash' ? 'active' : '',
        ]"
        @click="selectJournal('cash')"
        @keydown.enter="selectJournal('cash')"
        tabindex="0"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-currency-dollar-canadian"
          width="48"
          height="48"
          viewBox="0 0 24 24"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M21 6h-4a3 3 0 0 0 0 6h1a3 3 0 0 1 0 6h-4" />
          <path d="M10 18h-1a6 6 0 1 1 0 -12h1" />
          <path d="M17 20v-2" />
          <path d="M18 6v-2" />
        </svg>
        <div class="clearfix"></div>
        <br />
        <span>{{ $t("message.cash_journal") }}</span>
      </div>
    </div>
    <div class="card-list">
      <div
        :class="[
          'card-list__body',
          state.selectedJournal == 'misc' ? 'active' : '',
        ]"
        @click="selectJournal('misc')"
        @keydown.enter="selectJournal('misc')"
        tabindex="0"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-list"
          width="48"
          height="48"
          viewBox="0 0 24 24"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <line x1="9" y1="6" x2="20" y2="6" />
          <line x1="9" y1="12" x2="20" y2="12" />
          <line x1="9" y1="18" x2="20" y2="18" />
          <line x1="5" y1="6" x2="5" y2="6.01" />
          <line x1="5" y1="12" x2="5" y2="12.01" />
          <line x1="5" y1="18" x2="5" y2="18.01" />
        </svg>
        <div class="clearfix"></div>
        <br />
        <span>{{ $t("message.various_journal") }}</span>
      </div>
    </div>
    <div class="card-list">
      <div
        :class="[
          'card-list__body',
          state.selectedJournal == 'pay' ? 'active' : '',
        ]"
        @click="selectJournal('pay')"
        @keydown.enter="selectJournal('pay')"
        tabindex="0"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-cash-banknote"
          width="48"
          height="48"
          viewBox="0 0 24 24"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="12" r="3" />
          <rect x="3" y="6" width="18" height="12" rx="2" />
          <line x1="18" y1="12" x2="18.01" y2="12" />
          <line x1="6" y1="12" x2="6.01" y2="12" />
        </svg>
        <div class="clearfix"></div>
        <br />
        <span>Pay Journal</span>
      </div>
    </div> -->
  </div>
  <div class="clearfix"></div>
  <br />
  <br />
</template>

<script>
import { ref, reactive } from "@vue/reactivity";

import journalPreset from "@/config/journalPreset.js";
import SubJournalPopup from "../SubJournalPopup.vue";
import { onMounted } from "@vue/runtime-core";
import helper from "@/helper.js";
export default {
  emits: ["set", "setSubJournal"],
  components: { SubJournalPopup },
  setup(_, { emit }) {
    const emitter = helper().emitter();
    const popupTriggers = ref({
      popup: null,
      type: null,
      journal: null,
    });
    const togglePopup = async (popup, journal) => {
      popupTriggers.value.journal = journal;
      popupTriggers.value.popup = popup;
      popupTriggers.value.type = journal.type;
    };
    const journalPresetList = journalPreset();
    const state = reactive({
      selectedJournal: "buying",
    });
    function selectJournal(type) {
      state.selectedJournal = type;
      emit("set", type);
    }
    function setJournal(journal) {
      emit("setSubJournal", journal);
    }
    onMounted(() => {
      emitter.on("close-popup", () => {
        popupTriggers.value.popup = null;
        popupTriggers.value.type = null;
      });
    });
    return {
      state,
      popupTriggers,
      togglePopup,
      selectJournal,
      setJournal,
      journalPresetList,
    };
  },
};
</script>
