<template>
  <Popup :header="state.header" classes="md">
    <form action="" @submit.prevent="addSubJournal">
      <strong
        >{{
          formData.journalType == "bank" ? t("message.bank") : t("message.cash")
        }}
        {{ t("message.sub_journal") }}</strong
      >
      <div class="clearfix"></div>
      <br />
      <input type="hidden" v-model="formData.journalType" />
      <input
        type="text"
        class="form-input"
        placeholder="sub journal name"
        v-model="formData.subJournalName"
        required
      />
      <div class="clearfix"></div>
      <br />
      <button class="form-btn" type="submit">Create</button>
    </form>
  </Popup>
</template>

<script>
import { onMounted, reactive } from "@vue/runtime-core";
import Popup from "@/components/Popup.vue";
import { useI18n } from "vue-i18n";
import helper from "@/helper.js";
export default {
  emits: ["setJournal"],
  components: { Popup },
  props: ["journal"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const state = reactive({
      header: t("message.sub_journal"),
    });
    const formData = reactive({
      subJournalName: "",
      journalType: props.journal.type,
    });
    const emitter = helper().emitter();
    function addSubJournal() {
      emit("setJournal", formData);
    }
    onMounted(() => {
      emitter.emit("model-update");
    });
    return {
      state,
      t,
      addSubJournal,
      formData,
    };
  },
};
</script>
