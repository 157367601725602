<template>
  <transition name="slideLeft">
    <div v-if="state.step == 1">
      <Select
        @set="(type) => setJournal(type)"
        @setSubJournal="(type) => setSubJournal(type)"
      ></Select>
      <div class="text-center">
        <button class="form-btn" @click="nextStep">
          {{ $t("message.next") }}&nbsp;<svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-chevron-right"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="#ffffff"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <polyline points="9 6 15 12 9 18" />
          </svg>
        </button>
      </div>
    </div>
  </transition>

  <transition name="slideLeft">
    <div class="journal--master" v-if="state.step == 2">
      <Form
        :type="state.selectedJournal"
        :subJournalName="state.subJournalName"
      ></Form>
      <br />
      <div class="text-center">
        <router-link
          :to="`/journal/${state.clientUuid}/list`"
          tabindex="0"
          @click="backStep"
          @keydown.enter="backStep"
          class="back-link"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-arrow-left"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#597e8d"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <line x1="5" y1="12" x2="19" y2="12"></line>
            <line x1="5" y1="12" x2="11" y2="18"></line>
            <line x1="5" y1="12" x2="11" y2="6"></line></svg
          >&nbsp;{{ $t("message.back") }}</router-link
        >
      </div>
    </div>
  </transition>
</template>

<script>
import { onMounted, reactive } from "@vue/runtime-core";
import { useStore } from "vuex";
import Select from "./steps/Select.vue";
import Form from "./steps/Form.vue";
import router from "@/router";
export default {
  name: "NewJournal",
  components: { Select, Form },
  setup() {
    const store = useStore();
    const state = reactive({
      selectedJournal: "buying",
      step: 1,
      clientUuid: "",
      subJournalName: "",
    });

    // const client_uuid = computed(() => router.currentRoute.value.params.uuid);

    function selectJournal(type) {
      state.selectedJournal = type;
    }
    function nextStep() {
      state.step++;
    }
    function backStep() {
      state.step--;
    }
    function setJournal(type) {
      state.selectedJournal = type;
    }
    function setSubJournal(type) {
      state.selectedJournal = "sub_" + type.journalType;
      state.subJournalName = type.subJournalName;
      state.step++;
    }
    onMounted(() => {
      console.log("router", router.currentRoute.value.params.uuid);
      state.clientUuid = router.currentRoute.value.params.uuid;
      if (Object.keys(router.currentRoute.value.query).length == 0) {
        state.step = 1;
      } else {
        if (router.currentRoute.value.query.date != undefined) {
          state.step = 2;
        }
      }
      store.commit("component/SET_LOADER", false);
    });
    return {
      state,
      selectJournal,
      setJournal,
      setSubJournal,
      nextStep,
      backStep,
    };
  },
};
</script>
