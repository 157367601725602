<template>
  <div class="journal-card">
    <div class="journal-card__header">
      <h3 class="journal-card__title">Brouillard de Saisie</h3>
      <div class="clearfix"></div>
      <br />
      <div class="grid d" style="--c: 2fr 0.5fr 0.5fr; max-width: 50%">
        <span class="lbl-strong">{{ state.client?.company_name }}</span>
        <span class="lbl-strong">{{
          props.subJournalName
            ? props.subJournalName.toUpperCase()
            : state.labelAbbr[journalType]
        }}</span>
        <span class="lbl-strong"
          >{{
            props.subJournalName
              ? props.subJournalName.toUpperCase() + " "
              : ""
          }}{{ state.labelFull[journalType] }}</span
        >
      </div>
      <div class="journal-card__header--info">
        <div class="flex sib">
          <span>Periode du:&nbsp;</span>
          <span>
            <!-- <datepicker
              class="form-datepicker t-r"
              inputFormat="ddMMyy"
              v-on:change="formateDate"
              v-model="state.periode_du"
              style="--vdp-hover-bg-color: var(--pclr-5)"
            ></datepicker> -->
            <v-date-picker
              v-model="state.vdate"
              mode="date"
              :masks="state.masks"
              @dayclick="dayClick"
              class="t-r"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="bg-white border px-2 py-1 rounded"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </span>
        </div>
        <!-- <div class="flex sib">
          <span>au:&nbsp;</span>
          <span>01/09/21</span>
        </div> -->
        <div class="flex sib">
          <span>Tenue de compte:&nbsp;</span>
          <span>XAF</span>
        </div>
      </div>
    </div>
    <br />
    <div class="grid d" style="--c: 2fr 1.5fr 0.5fr 0.5fr">
      <span class="t-lbl">Prohada 1.0.0</span>
      <span class="t-lbl"
        >Date de tirage: {{ new Date().toLocaleString() }}</span
      >
      <span class="t-lbl">{{ $t("message.time") }}: 17:26:00</span>
    </div>
    <div class="clearfix"></div>
    <br />
    <div class="journal-card__body">
      <div
        class="jRow head"
        style="--col: 0.8fr 0.75fr 1.5fr 1.5fr 1.75fr 1fr 1fr 30px"
      >
        <span>Jour</span>
        <span>N<sup>◦</sup> pièce</span>
        <span>N<sup>◦</sup> compte</span>
        <span>N<sup>◦</sup> tiers</span>
        <span>{{ $t("message.libellè_ècriture") }}</span>
        <span class="dib t-r">{{ $t("message.Mvts_dèbit") }}</span>
        <span class="dib t-r">{{ $t("message.Mvts_crèdit") }}</span>
        <!-- <span class="dib t-r">Équilibre progressif</span> -->
      </div>
      <div class="jTable-loading" v-if="is_loader_loading">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div
        v-if="!is_loader_loading"
        class="jRow-body"
        @drop="onDrop($event)"
        @dragover.prevent
        @dragenter.prevent
      >
        <div
          :class="['jRow', k == state.line_no ? 'active' : '']"
          :pr-row="j.id"
          style="--col: 0.8fr 0.75fr 1.5fr 1.5fr 1.75fr 1fr 1fr 30px"
          v-for="(j, k) in state.journals"
          :key="k"
        >
          <!-- <span>{{ j.id }}</span> -->
          <span :class="['jRow-col', !j.entry_date ? 'empty' : '']">
            <!-- <datepicker
              placeholder="Jour"
              inputFormat="ddMMyy"
              v-model="j.entry_date"
              style="--vdp-hover-bg-color: var(--pclr-5)"
            ></datepicker> -->

            <v-date-picker
              v-model="j.entry_date"
              mode="date"
              :masks="state.masks"
              @dayclick="updateEntryDate($event, j)"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="bg-white border px-2 py-1 rounded"
                  :value="inputValue"
                  v-on="inputEvents"
              /></template>
            </v-date-picker>

            <!-- <input
                type="text"
                placeholder="Jour"
                @focus="state.line_no = k"
                contenteditable
                v-model="j.jour"
              /> -->
          </span>
          <!-- Part no / n_piece -->
          <span :class="['jRow-col', !j.part_number ? 'empty' : '']">
            <input
              placeholder="N Piece"
              @focus="state.line_no = k"
              contenteditable
              v-model="j.part_number"
              @blur="updateRow(j)"
            />
          </span>
          <!-- Account number / n_compte -->
          <span :class="['jRow-col', !j.account_number ? 'empty' : '']">
            <input
              placeholder="N Compte"
              @focus="state.line_no = k"
              @keyup="toggleMyCodesMenu(j, 'account')"
              @blur="
                calculateDebitCredit(j, 'account_number');
                updateRow(j, state.journals[k - 1], true);
              "
              contenteditable
              v-model="j.account_number"
            />
            <ul
              v-if="
                state.current_row == j.id &&
                state.active_dropdown_type == 'account'
              "
              class="form-dropdown form-group__dropdown w-400 mh-250 oa pa"
            >
              <li v-for="(code, k) in ohadaCodesForAccNo" :key="k">
                <a
                  @mousedown="
                    j.account_number = state.account_number_q =
                      code.code_type == 'prohada'
                        ? code.company_code
                        : code.code;
                    j.designation = code.description;
                    j.code_type = code.code_type;
                    state.current_row = -1;
                  "
                  >{{
                    code.code_type == "prohada"
                      ? `${code.company_code}`
                      : `${code.code} - ${code.description}`
                  }}</a
                >
              </li>
            </ul>
          </span>
          <!-- Tier Number / n_tiers -->
          <span :class="['jRow-col', !j.tier_number ? 'empty' : '']">
            <input
              placeholder="N Tier"
              @focus="state.line_no = k"
              @blur="
                calculateDebitCredit(j, 'tier_number');
                updateRow(j);
              "
              @keyup="toggleMyCodesMenu(j, 'tiers')"
              contenteditable
              v-model="j.tier_number"
            />
            <ul
              v-if="
                state.current_row == j.id &&
                state.active_dropdown_type == 'tiers'
              "
              class="form-dropdown form-group__dropdown w-400 mh-250 oa pa"
            >
              <li v-for="(code, k) in myCodesForNTiers" :key="k">
                <a
                  @mousedown="j.code_type = 'prohada'"
                  @click="
                    j.tier_number = state.tier_number_q = code.company_code;
                    state.current_row = -1;
                  "
                  >{{ `${code.company_code}` }}</a
                >
              </li>
            </ul>
          </span>
          <!-- Designation -->
          <span :class="['jRow-col', !j.designation ? 'empty' : '']">
            <input
              placeholder="Libellé écriture"
              @focus="state.line_no = k"
              @keyup="toggleMyCodesMenu(j, 'designation')"
              @blur="updateRow(j)"
              contenteditable
              v-model="j.designation"
            />
            <ul
              v-if="
                state.current_row == j.id &&
                state.active_dropdown_type == 'designation'
              "
              class="form-dropdown form-group__dropdown w-400 mh-250 oa pa"
            >
              <li v-for="(code, k) in ohadaCodesForDesignation" :key="k">
                <a @click="selectDesignation(j, code)">{{
                  `${code.description} - ${code.code}`
                }}</a>
              </li>
            </ul>
          </span>
          <span :class="['jRow-col', 't-r', !j.debit ? 'empty' : '']">
            <input
              placeholder="débits"
              @focus="state.line_no = k"
              @blur="updateRow(j, state.journals[k - 1], true)"
              contenteditable
              v-model="j.debit"
          /></span>
          <span :class="['jRow-col', 't-r', !j.credit ? 'empty' : '']">
            <input
              placeholder="crédits"
              @focus="state.line_no = k"
              contenteditable
              @blur="
                state.journals.length == k + 1 ? addRow() : '';
                updateRow(j, state.journals[k - 1], true);
              "
              v-model="j.credit"
          /></span>
          <!-- Progressive Balance -->
          <span
            :class="[
              'jRow-col dn',
              't-r',
              !j.progressive_balance ? 'empty' : '',
            ]"
          >
            <input
              placeholder="0"
              @focus="state.line_no = k"
              contenteditable
              @blur="
                state.journals.length == k + 1 ? addRow() : '';
                updateRow(j);
              "
              v-model="j.progressive_balance"
            />
          </span>
          <!-- Drag & drop -->
          <!-- <span
            @mousedown="selectRow(j)"
            class="jRow-col t-c p0 flex a-c j-c c-p"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-menu"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#999"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <line x1="4" y1="8" x2="20" y2="8" />
              <line x1="4" y1="16" x2="20" y2="16" />
            </svg>
          </span> -->
          <span class="jRow-col p0 t-c has-dropdown flex a-c j-c c-p"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-dots"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="#666"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="5" cy="12" r="1" />
              <circle cx="12" cy="12" r="1" />
              <circle cx="19" cy="12" r="1" />
            </svg>
            <ul class="jRow-dropdown">
              <li @click="addRowAtIndex(j)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-plus"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <line x1="12" y1="5" x2="12" y2="19" />
                  <line x1="5" y1="12" x2="19" y2="12" /></svg
                >&nbsp;Add
              </li>
              <li @click="deleteRow(j, k)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-trash"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <line x1="4" y1="7" x2="20" y2="7" />
                  <line x1="10" y1="11" x2="10" y2="17" />
                  <line x1="14" y1="11" x2="14" y2="17" />
                  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg
                >&nbsp;Delete
              </li>
              <li @click="duplicateRow(j)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-copy"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <rect x="8" y="8" width="12" height="12" rx="2" />
                  <path
                    d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"
                  /></svg
                >&nbsp;{{ $t("message.duplicate") }}
              </li>
            </ul>
          </span>
        </div>
        <div style="--col: 6.3fr 1fr 1fr 1fr 30px 30px" class="jRow jRow-total">
          <div class="jRow-col t-r">A reporter</div>
          <div class="jRow-col t-r">{{ total_debit }}</div>
          <div class="jRow-col t-r">{{ total_credit }}</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
        </div>
      </div>
      <div class="clearfix"></div>
      <br />
      <div class="t-c">
        <button class="form-btn xs" @click="addRow">
          +&nbsp;{{ $t("message.add") }}
        </button>
      </div>
      <div class="f-r">
        <button @click="generatePdf" v-if="!pdf.generating" class="form-btn sm">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-file-text"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#ffffff"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
            <path
              d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"
            />
            <line x1="9" y1="9" x2="10" y2="9" />
            <line x1="9" y1="13" x2="15" y2="13" />
            <line x1="9" y1="17" x2="15" y2="17" /></svg
          >&nbsp;{{ $t("message.pdf") }}
        </button>
        <button class="form-btn disabled" disabled v-if="pdf.generating">
          Generating...
        </button>
      </div>
      <div class="clearfix"></div>
      <br />
    </div>
    <div class="clearfix"></div>
    <br />
  </div>
</template>
<script>
import { computed, reactive, ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import router from "@/router";
// import Datepicker from "vue3-datepicker";
import { useStore } from "vuex";
import { v4 as uuidv4 } from "uuid";
import { codes } from "@/config/codes.json";
import helper from "@/helper";
export default {
  props: ["type", "subJournalName"],
  // components: { Datepicker },
  setup(props) {
    let journalType = computed(() => props.type).value;
    if (router.currentRoute.value.query.type) {
      journalType = router.currentRoute.value.query.type.toLowerCase();
    }
    const pdf = ref({ generating: 0 });
    const store = useStore();
    const picked = ref(new Date());
    const is_loader_loading = ref(1);
    const state = reactive({
      masks: {
        input: "DDMMYY",
      },
      modelConfig: {
        mask: "YYYY-MM-DD",
      },
      vdate: new Date(),
      calendarDate: new Date(),
      mouse: { x: 0, y: 0 },
      line_no: -1,
      row_index: 0,
      piece_index: 1,
      periode_du: new Date(),
      journals: [
        // {
        //   id: 0,
        //   jour: new Date(),
        //   part_number: 54,
        //   account_number: 445679,
        //   tier_number: "401Diver",
        //   designation: "PIECE AUTO",
        //   debit: "248,10",
        //   credit: "1490",
        // },
      ],
      current_row: -1,
      account_number_q: "",
      tier_number_q: "",
      designation_q: "",
      active_dropdown_type: "",
      client: null,
      labelAbbr: codes.labelAbbr,
      labelFull: codes.labelFull,
      specialCode: codes.specialCodes,
      allowedMagic: codes.allowedMagic,
    });

    const client_uuid = computed(() => router.currentRoute.value.params.uuid);
    const myCodes = computed(() => {
      return store.state.code.myCodes.filter(
        (code) => code.client_id == state.client?.id
      );
    });
    const myCodesForNTiers = computed(() => {
      return myCodes.value.filter((code) => {
        return code.company_code.includes(state.tier_number_q);
      });
    });

    const ohadaCodes = computed(() => {
      return store.state.code.codes;
    });

    const userOhadaCodes = computed(() => {
      return store.state.code.userOhadaCodes;
    });

    const ohadaCodesForDesignation = computed(() => {
      return ohadaCodes.value.filter((code) => {
        return code.description
          .toLowerCase()
          .includes(state.designation_q.toLowerCase());
      });
    });

    const ohadaCodesForAccNo = computed(() => {
      let filterOhadaCodes = userOhadaCodes.value.filter((code) => {
        // Code type ohada fetched from OHADA Standard code
        code.code_type = "ohada";
        return code.code.includes(state.account_number_q);
      });
      // let filterMyCodes = myCodes.value.filter((code) => {
      //   // Prohada is personal code(fetched from myCodes);
      //   code.code_type = "prohada";
      //   return code.company_code.includes(state.account_number_q);
      // });
      // return { ...filterOhadaCodes, ...filterMyCodes };
      return { ...filterOhadaCodes };
    });
    const total_debit = computed(() => {
      return state.journals?.reduce(
        (n, { debit }) => parseInt(Number(n)) + parseInt(Number(debit)),
        0
      );
    });
    const total_credit = computed(() => {
      return state.journals?.reduce(
        (n, { credit }) => parseInt(Number(n)) + parseInt(Number(credit)),
        0
      );
    });

    // const ohadaCodes = prohadaCodes.filter((code) => {
    //   return code.code.includes(state.account_number_q);
    // });
    // const ohadaCodes = computed(() => {
    //   return store.state.code.codes.filter((code) => {
    //     return code.code.includes(state.account_number_q);
    //   });
    // });
    function createRowData() {
      // check wheather last row contains special acc no or not.
      let check_last_acc_no = undefined;
      if (state.journals[0] != undefined) {
        check_last_acc_no =
          state.journals[state.journals.length - 1].account_number;
      }
      state.row_index++;

      let debit = "";
      let credit = "";

      let increaseIndex = true;
      // change increaseIndex according to conditions.
      switch (journalType) {
        case "bank":
          if (
            check_last_acc_no == 521 &&
            state.journals[state.journals.length - 1].debit > 0
          ) {
            increaseIndex = false;
          }
          break;
        case "cash":
          if (
            check_last_acc_no == 571 &&
            state.journals[state.journals.length - 1].debit > 0
          ) {
            increaseIndex = false;
          }
          break;
      }
      let isMagicCode = false;
      if (check_last_acc_no && state.allowedMagic[journalType]) {
        let accNumberToCheck = check_last_acc_no?.match(/(\d+)/)[0];
        switch (state.journals[state.journals.length - 1].code_type) {
          case "prohada":
            if (
              state.specialCode[journalType].filter(
                (c) => c.code == accNumberToCheck
              ).length >= 1
            )
              isMagicCode = true;
            break;
          case "ohada":
            if (
              state.specialCode[journalType].filter(
                (c) => c.code == check_last_acc_no
              ).length >= 1
            )
              isMagicCode = true;
            break;
        }
      }

      if (
        check_last_acc_no &&
        check_last_acc_no != undefined &&
        increaseIndex &&
        isMagicCode
      ) {
        state.piece_index = Number(state.piece_index) + 1;
      }
      let row = {
        id: state.row_index,
        uuid: uuidv4(),
        entry_date: new Date(),
        part_number: Number(state.piece_index),
        account_number: "",
        tier_number: "",
        designation: "",
        debit: debit,
        credit: credit,
        progressive_balance: "0",
        is_401: false,
        is_411: false,
        created_at: state.periode_du,
      };
      return row;
    }
    function addRow() {
      let row = createRowData();
      state.journals.push(row);
    }
    function calculateReccursiveDebitCredit(
      len,
      total,
      codeToCheck,
      calculationType,
      specialCases = ""
    ) {
      if (len >= 1) {
        const currentRow = state.journals[len - 1];
        let row_total = {
          credit: 0,
          debit: 0,
        };
        let breakLoop = false;

        if (currentRow.account_number != codeToCheck) {
          switch (calculationType) {
            case "debit":
              row_total.debit = state.journals[len - 1].debit;
              break;
            case "credit":
              row_total.credit = state.journals[len - 1].credit;
              break;
            default:
              row_total.debit = state.journals[len - 1].debit;
              row_total.credit = state.journals[len - 1].credit;
              break;
          }
          // Bank & Cash journal calulation for code 411
          if (specialCases.checkPrev) {
            total.debit = total.debit + Number(row_total.debit);
            total.credit = total.credit + Number(row_total.credit);
            breakLoop = true;
          }
        } else {
          breakLoop = true;
        }

        if (!breakLoop) {
          total.debit = total.debit + Number(row_total.debit);
          total.credit = total.credit + Number(row_total.credit);
          return calculateReccursiveDebitCredit(
            len - 1,
            total,
            codeToCheck,
            calculationType,
            specialCases
          );
        }
      }
      return total;
    }
    // Calculate total credit / debit on account 401 / 411
    function calculateDebitCredit(row, fieldName) {
      // let codeToCheck = Number(row.account_number.slice(0, 3));
      // const specialCodesSet = new Set([]);
      // Object.values(state.specialCode).map((c) =>
      //   c.map((v) => specialCodesSet.add(v))
      // );
      // Get row length
      let row_len = state.journals.length;
      let calculateRecBalance = false;
      let accNumberToCheck;
      // const specialCodesArray = [...specialCodesSet];
      if (state.allowedMagic[journalType]) {
        state.specialCode[journalType].map((eleRow) => {
          // Check wether code is magic code or not.
          switch (row.code_type) {
            case "prohada":
              accNumberToCheck = row[fieldName].match(/(\d+)/)[0];
              if (accNumberToCheck == eleRow.code) calculateRecBalance = true;
              break;
            case "ohada":
              accNumberToCheck = row[fieldName];
              if (eleRow.code == Number(row[fieldName]))
                calculateRecBalance = true;
              break;
          }
          // If code is a magic code then calculate debit/credit balance
          if (calculateRecBalance) {
            const grand_total = calculateReccursiveDebitCredit(
              row_len - 1,
              {
                credit: 0,
                debit: 0,
              },
              row[fieldName],
              eleRow.calculationType,
              eleRow.cases
            );
            row.debit = Number(grand_total.credit);
            row.credit = Number(grand_total.debit);
          }
          // let eleCodeLength = eleRow.code.toString().length;
          // let accountNoCodeLength = row.account_number.length;
          // if (eleCodeLength <= accountNoCodeLength) {
          //   codeToCheck = Number(row.account_number.slice(0, eleCodeLength));
          //   console.log(codeToCheck, eleRow.code);
          // }
        });
      }

      // console.log(specialCodesArray, codeToCheck);
      // if (specialCodesArray.includes(codeToCheck)) {
      // const grand_total = calculateReccursiveDebitCredit(
      //   row_len - 1,
      //   {
      //     credit: 0,
      //     debit: 0,
      //   },
      //   codeToCheck,
      //   calculationType
      // );
      // row.debit = Number(grand_total.credit);
      // row.credit = Number(grand_total.debit);
      // }
    }
    function addRowAtIndex(row) {
      let row_data = createRowData();
      state.journals.splice(row.id + 1, 0, row_data);

      let index = 0;
      state.journals.forEach((j) => {
        j.id = index++;
      });
    }
    function duplicateRow(row) {
      let row_id = row.id;
      state.row_index++;
      row.id = state.row_index;
      let duplicateRow = { ...row };
      state.journals.splice(row_id + 1, 0, duplicateRow);

      let index = 0;
      state.journals.forEach((j) => {
        j.id = index++;
      });
    }
    function deleteRow(row, k) {
      if (row.account_number == "") {
        state.journals.splice(k, 1);
      } else {
        store.dispatch("journal/deleteRow", row).then((res) => {
          if (res.data.status == "success") {
            state.journals = state.journals.filter((j) => row.id != j.id);
          } else {
            alert("Something went wrong. Try again after sometime.");
          }
        });
      }
    }
    // Drag & drop
    function selectRow(item) {
      state.draggable_id = item.id;
    }

    function selectDesignation(row, code) {
      row.designation = state.designation_q = code.description;
      row.account_number = code.code;
      state.current_row = -1;
      if (
        row.account_number?.slice(0, 3) == state.specialCode.buying ||
        row.account_number?.slice(0, 3) == state.specialCode.selling
      ) {
        calculateDebitCredit(row);
      }
    }

    async function toggleMyCodesMenu(row, type) {
      if (type == "tiers") {
        if (
          row.tier_number &&
          ((row.tier_number?.slice(0, 3) == state.specialCode.buying &&
            journalType == "buying") ||
            (row.tier_number?.slice(0, 3) == state.specialCode.selling &&
              journalType == "selling") ||
            (row.tier_number?.slice(0, 3) == state.specialCode.bank &&
              journalType == "bank") ||
            (row.tier_number?.slice(0, 3) == state.specialCode.cash &&
              journalType == "cash") ||
            (row.tier_number?.slice(0, 3) == state.specialCode.misc &&
              journalType == "misc"))
        ) {
          // row.account_number = 401;
          let codeToMatch = state.specialCode[journalType];
          // if (journalType == "buying") codeToMatch = state.specialCode.buying;
          // if (journalType == "selling")
          //   codeToMatch = state.specialCode.selling;
          // if (journalType == "bank") codeToMatch = state.specialCode.bank;
          let index_acc = ohadaCodes.value.findIndex(
            (x) => x.code == codeToMatch
          );
          row.account_number = ohadaCodes.value[index_acc].code;
          row.designation = ohadaCodes.value[index_acc].description;
          if (journalType.match("buying", "selling") != null) {
            calculateDebitCredit(row);
          }
        }
      }
      state.active_dropdown_type = type;
      var list_item_count;
      switch (type) {
        case "account":
          state.account_number_q = row.account_number;
          list_item_count = await ohadaCodes.value.filter((c) => {
            return c.code.includes(row.account_number);
          }).length;
          if (row.account_number.length >= 2 && list_item_count > 0) {
            state.current_row = row.id;
          } else {
            state.current_row = -1;
          }
          break;
        case "tiers":
          state.tier_number_q = row.tier_number;
          list_item_count = await myCodes.value.filter((c) => {
            return c.company_code.includes(row.tier_number);
          }).length;
          if (row.tier_number?.length >= 2 && list_item_count > 0) {
            state.current_row = row.id;
          } else {
            state.current_row = -1;
          }
          break;
        case "designation":
          state.designation_q = row.designation;
          list_item_count = await ohadaCodes.value.filter((c) => {
            return c.description
              .toLowerCase()
              .includes(row.designation.toLowerCase());
          }).length;
          if (row.designation?.length >= 2 && list_item_count > 0) {
            state.current_row = row.id;
          } else {
            state.current_row = -1;
          }
          break;
      }
    }

    const calculateProgressiveBalance = (row, prevRow) => {
      if (prevRow == null) row.progressive_balance = row.debit;
      else if (row.debit && row.debit != 0) {
        row.progressive_balance =
          Number(prevRow.progressive_balance) + Number(row.debit);
      } else {
        row.progressive_balance =
          Number(prevRow.progressive_balance) - Number(row.credit);
      }
    };

    const updateEntryDate = (dateObject, row) => {
      // row.entry_date = new Date(dateObject.date);
      updateRow(row);
    };

    const updateRow = (row, prevRow = null, calculatePb = false) => {
      if (row && row.account_number) {
        if (calculatePb) calculateProgressiveBalance(row, prevRow);
        const formData = new FormData();
        setTimeout(() => {
          formData.append("uuid", row.uuid);
          formData.append("client_id", state.client.id);
          row.created_at = new Date(row.created_at);
          formData.append(
            "entry_date",
            // `${row.entry_date.getFullYear()}-${
            //   Number(row.entry_date.getMonth()) + 1
            // }-${row.entry_date.getDate()} ${row.entry_date
            //   .toLocaleTimeString()
            //   .substring(0, 8)}`
            helper().filterDate(row.entry_date)
          );
          formData.append("part_number", row.part_number);
          formData.append("account_number", row.account_number);
          formData.append("tier_number", row.tier_number || "");
          formData.append("designation", row.designation || "");
          formData.append("debit", row.debit || "");
          formData.append("credit", row.credit || "");
          if (props.subJournalName) {
            formData.append("name", props.subJournalName);
            formData.append("type", journalType);
            if (journalType == "sub_bank") {
              formData.append("parent_journal", "bank");
            } else {
              formData.append("parent_journal", "cash");
            }
          } else {
            formData.append("type", journalType);
          }
          formData.append("code_type", row.code_type || "ohada");
          formData.append("progressive_balance", row.progressive_balance);
          // console.log("formdata", formData);
          formData.append("created_at", helper().filterDate(row.created_at));
          store.dispatch("journal/updateRow", formData);
        }, 250);
      }
    };

    const mouseUpHandler = () => {
      state.draggable_id = -1;
    };
    // const getRow = (elem) => {
    //   if (!elem.classList.contains("jRow-col")) {
    //     getRow(elem.parentNode);
    //   } else {
    //     return elem;
    //   }
    // };
    const mouseMoveHandler = (evt) => {
      state.mouse.x = evt.clientX;
      state.mouse.y = evt.clientY;

      // let elem = evt.target;

      // Select row on mouse over
      // let gr = getRow(elem);

      if (state.draggable_id > 0) {
        const row = document.querySelector(
          "[pr-row='" + state.draggable_id + "']"
        );
        row.style.position = "absolute";
      }
    };

    const dayClick = async (day) => {
      state.periode_du = day.date;
      // updateRow(row);
      fetchEntries();
    };

    const fetchEntries = async () => {
      state.journals = [];
      await store
        .dispatch("journal/fetchEntries", {
          type: journalType,
          client_uuid: client_uuid.value,
          from_date: state.periode_du,
          to_date: state.periode_du,
          name: props.subJournalName,
        })
        .then((res) => {
          is_loader_loading.value = false;
          res.forEach((item) => {
            item.entry_date = new Date(item.entry_date);
            item.debit = item.debit || 0;
            item.credit = item.credit || 0;
            state.journals.push(item);
          });
          if (!res.length) addRow();
        });
    };

    const generatePdf = async () => {
      pdf.value.generating = 1;
      await store
        .dispatch("journal/generatePdf", {
          client_id: state.client.id,
          journal_type: journalType,
          operation_type: "new-journal",
          print_date: helper().filterDate(state.periode_du),
          name: props.subJournalName,
        })
        .then((res) => {
          pdf.value.generating = 0;
          if (res.status == "success") {
            let linkElement = document.createElement("a");
            linkElement.setAttribute("href", res.data);
            linkElement.setAttribute("target", "_blank");
            document.body.appendChild(linkElement);
            linkElement.click();
            document.body.removeChild(linkElement);
          }
        });
    };

    onMounted(async () => {
      if (router.currentRoute.value.query.date != undefined) {
        state.vdate = router.currentRoute.value.query.date;
        // state.periode_du = new Date(router.currentRoute.value.query.date);
        state.periode_du = helper().convertToDateObject(
          router.currentRoute.value.query.date
        );
        // let vDate = new Date(router.currentRoute.value.query.date);
        // state.periode_du = helper().filterDate(vDate);
      }
      if (!myCodes.value?.length) {
        await store.dispatch("code/fetchMyCodes");
      }

      await store.dispatch("code/fetchOhadaCodes");

      // if (!userOhadaCodes.value?.length) {
      await store.dispatch("code/fetchUserOhadaCodes");
      // }
      // Fetch journals on load
      // Fetch journal entries on page load
      await fetchEntries();
      if (!state.client)
        store
          .dispatch("client/fetchClientByUUID", { uuid: client_uuid.value })
          .then((res) => {
            state.client = res.data;
          });
      // for (let i = 0; i <= 2; i++) {
      //   addRow();
      // }
      // Store piece number in state
      if (state.journals.length > 0) {
        state.piece_index = Number(
          state.journals[state.journals.length - 1].part_number
        );
      }
      // Mouse move / Drag & drop
      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler);
    });
    return {
      is_loader_loading,
      state,
      picked,
      myCodes,
      myCodesForNTiers,
      ohadaCodes,
      ohadaCodesForAccNo,
      ohadaCodesForDesignation,
      selectDesignation,
      addRow,
      updateRow,
      updateEntryDate,
      calculateDebitCredit,
      addRowAtIndex,
      duplicateRow,
      deleteRow,
      selectRow,
      toggleMyCodesMenu,
      dayClick,
      total_debit,
      total_credit,
      generatePdf,
      pdf,
      journalType,
      props,
    };
  },
};
</script>
